export enum gdsPropertyLabelRus {
    name = 'Название',
    status = 'Статус',
    power = 'Мощность',
    year = 'Год постройки',
    year_upgrade = 'Год планируемой модернизации',
    power_upgrade = 'Мощность после модернизации',
    parent = 'Принадлежность',
    gro = 'Газораспределительная организация',
}
