import {
    COLORS,
    CONSUMER_STATUS_OPERATING,
    CONSUMER_STATUS_PROJECTED,
    CONSUMERS_TYPE,
    GDS_DATA_CITY_AREA,
    GDS_STATUS_CLOSED,
    GDS_STATUS_LIMITED,
    GDS_STATUS_OPERATING,
    GDS_STATUS_PROJECTED,
    GDS_TYPE,
    IMG_GDS_STATUS_CLOSED,
    IMG_GDS_STATUS_LIMITED,
    IMG_GDS_STATUS_OPERATING,
    IMG_GDS_STATUS_PROJECTED,
    PIPELINE_BRANCH_TYPE,
    PIPELINE_MAJOR_TYPE,
    PIPELINE_SETTLEMENT_TYPE,
    PIPELINE_STATUS_OPERATING,
    PIPELINE_STATUS_PROJECTED,
    PIPELINE_STATUS_UNDER_CONSTRUCTION,
    PIPELINE_TYPE_BRANCH,
    PIPELINE_TYPE_INTER_SETTLEMENT,
    PIPELINE_TYPE_MAJOR,
} from '@/shared/constants'

const getMultipleSvg = (template, count) => {
    let result = template
    for (let i = 0; i < count - 1; i++) result += template
    return result
}
const getPipelineHtml = id => {
    return `<svg viewBox="0 0 100 16" xmlns="http://www.w3.org/2000/svg"><use href="#${id}" /></svg>`
}
const getCityIconHtml = function (this: any, status) {
    switch (status) {
        case 'CONNECTED':
            return `<div class="getCityMarker getCityMarker_active" style="border-color: ${COLORS[35]}; background-color: ${COLORS[41]}"></div>`
        case 'DISCONNECTED':
            return `<div class="getCityMarker getCityMarker_perspective" style="border-color: ${COLORS[20]}; background-color: ${COLORS[25]}"></div>`
    }
}
const getGdsIconHtml = function (this: any, status) {
    switch (status) {
        case GDS_STATUS_CLOSED:
            return this.getGdsMarkerElement(IMG_GDS_STATUS_CLOSED).outerHTML
        case GDS_STATUS_LIMITED:
            return this.getGdsMarkerElement(IMG_GDS_STATUS_LIMITED).outerHTML
        case GDS_STATUS_OPERATING:
            return this.getGdsMarkerElement(IMG_GDS_STATUS_OPERATING).outerHTML
        case GDS_STATUS_PROJECTED:
            return this.getGdsMarkerElement(IMG_GDS_STATUS_PROJECTED).outerHTML
    }
}
const getPipelineMajorIconHtml = function (this: any, status) {
    switch (status) {
        case PIPELINE_STATUS_PROJECTED:
            return getPipelineHtml('pipelineMajorProjected')
        case PIPELINE_STATUS_UNDER_CONSTRUCTION:
            return getMultipleSvg(
                this.getPipelineSvgPattern(PIPELINE_TYPE_MAJOR, 6, 2),
                5
            )
        case PIPELINE_STATUS_OPERATING:
            return getPipelineHtml('pipelineMajorOperating')
    }
}
const getPipelineBranchIconHtml = function (this: any, status) {
    switch (status) {
        case PIPELINE_STATUS_PROJECTED:
            return getPipelineHtml('pipelineBranchProjected')
        case PIPELINE_STATUS_UNDER_CONSTRUCTION:
            return getMultipleSvg(
                this.getPipelineSvgPattern(PIPELINE_TYPE_BRANCH, 4, 2),
                6
            )
        case PIPELINE_STATUS_OPERATING:
            return getPipelineHtml('pipelineBranchOperating')
    }
}
const getPipelineSettlementIconHtml = function (this: any, status) {
    switch (status) {
        case PIPELINE_STATUS_PROJECTED:
            return getPipelineHtml('pipelineSettlementProjected')
        case PIPELINE_STATUS_UNDER_CONSTRUCTION:
            return getMultipleSvg(
                this.getPipelineSvgPattern(
                    PIPELINE_TYPE_INTER_SETTLEMENT,
                    3,
                    2
                ),
                7
            )
        case PIPELINE_STATUS_OPERATING:
            return getPipelineHtml('pipelineSettlementOperating')
    }
}
export default function (this: any) {
    switch (this.objectsType) {
        case GDS_DATA_CITY_AREA:
            return getCityIconHtml.call(this, this.featureProperties.status)
        default:
            return ''
    }
}
