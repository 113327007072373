<template>
    <f7-card
        outline
        :style="{ padding: 'var(--indent2)' }"
        :class="$style.gasificationPlanCard"
    >
        <f7-preloader v-if="isGdsDataLoading" :class="$style.gdsLoader" />

        <f7-block>
            <f7-block-title
                >Описание ГРС
                {{ gdsData.GDS_DATA_GDS_INFO?.name || gdsName }}</f7-block-title
            >
            <el-descriptions
                v-if="isGdsDataInfoEmpty"
                direction="horizontal"
                :column="1"
                border
            >
                <div
                    v-for="(
                        gdsPropertyValue, gdsPropertyName, index
                    ) in gdsData.GDS_DATA_GDS_INFO"
                    :key="index"
                >
                    <el-descriptions-item
                        v-if="
                            gdsPropertyValue &&
                            gdsPropertyLabelRus.hasOwnProperty(gdsPropertyName)
                        "
                        :label="gdsPropertyLabelRus?.[gdsPropertyName]"
                    >
                        {{ gdsPropertyValue }}
                    </el-descriptions-item>
                </div>
            </el-descriptions>
            <!--                    </el-scrollbar>-->
        </f7-block>

        <f7-block-title v-if="allGdsData.length"
            >Подключение населенных пунктов</f7-block-title
        >
        <f7-list :class="$style.accordion" accordion-list inset>
            <!-- title="Условные обозначения" -->
            <template
                v-if="!gdsData.GDS_DATA_CITY_AREA?.length && !isGdsDataLoading"
            >
                <f7-card
                    outline
                    :style="{ padding: 'var(--indent2)' }"
                    :class="$style.gasificationPlanCard"
                >
                    Нет данных
                </f7-card>
            </template>
            <f7-list-item accordion-item accordion-item-opened v-else>
                <template
                    #title
                    v-if="gdsData.GDS_DATA_GDS_INFO?.name || gdsName"
                >
                    {{ gdsData.GDS_DATA_GDS_INFO?.name || gdsName }}</template
                >
                <f7-accordion-content>
                    <f7-block>
                        <div :class="$style.districtInfoBlockWrapper">
                            <!-- @change="handleChange" -->
                            <div :class="$style.content">
                                <el-collapse
                                    v-model="activeCollapseNames"
                                    :class="$style.collapse"
                                >
                                    <template
                                        v-for="(loadDataResult, key) of gdsData"
                                    >
                                        <GdsObjectsList
                                            v-if="
                                                // loadDataResult?.source?.features
                                                loadDataResult?.length
                                            "
                                            :key="key"
                                            :class="$style.objectsList"
                                            @removeHighlighter="
                                                removeHighlighter
                                            "
                                            v-bind="{
                                                objectsType: key,
                                                source: loadDataResult,
                                                highlightSourceAndLayerId,
                                                highlightSourceAndLayers,
                                            }"
                                        />
                                    </template>
                                </el-collapse>
                            </div>
                        </div>
                    </f7-block>
                </f7-accordion-content>
            </f7-list-item>
        </f7-list>
    </f7-card>
</template>
<script>
import { mapState } from 'vuex'

import { GDS_DATA_CITY_AREA } from '@/shared/constants'

import GdsObjectsList from './components/GdsObjectsList/index'
import { gdsPropertyLabelRus } from './types'

const types = {
    GDS_DATA_CITY_AREA,
}

export default {
    name: 'GdsView',
    components: {
        GdsObjectsList,
    },
    data() {
        return {
            gdsPipelinesData: null,
            activeCollapseNames: Object.keys(types),
            highlightSourceAndLayerId: `highlighter_gds_city`,
            gdsPropertyLabelRus,
        }
    },
    computed: {
        ...mapState({
            gdsData: state => state.view.gdsMenu.gdsData,
            isGdsDataLoading: state => state.view.gdsMenu.isGdsDataLoading,
            gdsName: state => state.view.gdsMenu.gdsName,
            gdsState: state => state.view.gdsMenu.state,
            gdsId: state => state.view.gdsMenu.gdsId,
        }),
        highlightSourceAndLayers() {
            return [
                this.highlightSourceAndLayerId,
                `${this.highlightSourceAndLayerId}_static`,
            ]
        },
        allGdsData() {
            return Object.values(this.gdsData)
        },
        isGdsDataInfoEmpty() {
            return this.gdsData.GDS_DATA_GDS_INFO
                ? Object.entries(this.gdsData.GDS_DATA_GDS_INFO).length
                : null
        },
        // isShowGdsData() {
        //   return (
        //       !this.isGdsDataLoading && this.gdsData..length
        //   )
        // },
    },
    watch: {
        gdsState() {
            this.removeHighlighter()
        },
        gdsId() {
            this.removeHighlighter()
        },
    },
    methods: {
        removeHighlighter() {
            console.log('removeHighlighter')
            const currentHighlightLayers = this.highlightSourceAndLayers.map(
                layerId => {
                    return this.$map.getLayer(layerId)
                }
            )
            console.log('currentHighlightLayers', currentHighlightLayers)
            if (!currentHighlightLayers[0]) return
            this.highlightSourceAndLayers.forEach(layerId =>
                this.$map.removeLayer(layerId)
            )
        },
    },
    mounted() {
        this.$state.view.gdsMenu.isOpen = true

        console.log('gdsPropertyLabelRus', gdsPropertyLabelRus)
    },
    unmounted() {
        this.$state.view.gdsMenu.isOpen = false
    },
    created() {
        this.$state.map.highlightSourceAndLayers = [
            ...this.$state.map.highlightSourceAndLayers,
            ...this.highlightSourceAndLayers,
        ]
    },
}
</script>

<style lang="stylus" module>
.page
  > :global(.page-content)
    min-height 100%
    padding-top var(--f7-page-navbar-offset)

.gdsLoader
    align-self center
.gasificationYearTriggers
    display flex
    min-width 100%
.gasificationYearTrigger
    width 100%
    &:not(:first-child)
        margin-left var(--indent2)

.gasificationPlanCard
    display flex
    flex-direction column
    justify-content center

  width 100%
  display flex
  flex-direction column
.header
  flex-shrink 0
  display flex
  align-items center
  padding var(--indent1)
.title
  width 100%
  padding-right var(--indent1)
  text-transform uppercase
  font-weight bold
.closeTrigger
  flex-shrink 0
.content
  width 100%
  display flex
  justify-content center
// border-left 1px solid var(--el-border-color-lighter)
//     height 100%
//     overflow hidden
// .objectsList
//     margin-top var(--indent1)

.collapse
  width 100%
  &:global(.el-collapse)
    --el-collapse-header-background-color transparent
    --el-collapse-content-background-color transparent
    border none
  :global(.el-collapse-item__header)
    align-items baseline
    padding 0 var(--indent1)
</style>
