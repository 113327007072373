//
import { COLORS } from '@/shared/constants'
import { store } from '@/app/providers'

const highlighterOpacity = 0.6
export default function (this: any, featureData) {
    const framesPerSecond = 30
    const initialOpacity = 1
    let opacity = initialOpacity
    const initialRadius = 6
    let radius = initialRadius
    const maxRadius = 50
    let timeOutMarker, animationFrameId

    const currentHighlightSource = this.$map.getSource(
        this.highlightSourceAndLayerId
    )
    if (currentHighlightSource) {
        currentHighlightSource.setData(featureData)
    } else {
        this.$map.addSource(this.highlightSourceAndLayerId, {
            type: 'geojson',
            data: featureData,
        })
    }

    // this.removeHighlighter()
    this.$emit('removeHighlighter')

    const getHighlightColor = (status: string): string[] | any => {
        switch (status) {
            case 'CONNECTED':
                // return [COLORS[30], COLORS[32]]
                return [COLORS[41], COLORS[38]]
            case 'DISCONNECTED':
                return [COLORS[31], COLORS[25]]
        }
    }

    const color: string[] = getHighlightColor(featureData.properties.status)

    if (this.featureGeometryType === 'Point') {
        this.$map.addLayer({
            id: this.highlightSourceAndLayerId,
            source: this.highlightSourceAndLayerId,
            type: 'circle',
            paint: {
                'circle-radius': initialRadius,
                'circle-radius-transition': { duration: 0 },
                'circle-opacity-transition': { duration: 0 },
                'circle-color': color[0],
            },
        })
        this.$map.addLayer({
            id: `${this.highlightSourceAndLayerId}_static`,
            source: this.highlightSourceAndLayerId,
            type: 'circle',
            paint: {
                'circle-radius': initialRadius,
                'circle-color': color[1],
                'circle-stroke-width': 1,
                'circle-stroke-color': color[0],
            },
        })
    } else {
        this.$map.addLayer({
            id: this.highlightSourceAndLayerId,
            type: 'line',
            source: this.highlightSourceAndLayerId,
            paint: {
                'line-opacity': highlighterOpacity,
                'line-color': color,
                'line-width': 7.5,
            },
        })
    }

    const animateMarker = timestamp => {
        const currentHighlightLayers = this.highlightSourceAndLayers.map(
            layerId => {
                return this.$map.getLayer(layerId)
            }
        )
        if (!currentHighlightLayers[0]) {
            this.$state.view.gdsMenu.isActiveCityMarker = false
            return
        }
        timeOutMarker = setTimeout(() => {
            animationFrameId = requestAnimationFrame(animateMarker)

            radius += (maxRadius - radius) / framesPerSecond
            opacity -= 0.9 / framesPerSecond

            if (opacity <= 0) {
                radius = initialRadius
                opacity = initialOpacity
            }

            this.$map.setPaintProperty(
                this.highlightSourceAndLayerId,
                'circle-radius',
                radius
            )
            this.$map.setPaintProperty(
                this.highlightSourceAndLayerId,
                'circle-opacity',
                opacity
            )
        }, 1000 / framesPerSecond)
    }

    if (!this.$state.view.gdsMenu.isActiveCityMarker) {
        this.$state.view.gdsMenu.isActiveCityMarker = true
        // Start the animation.
        animateMarker(0)
    }

    store.watch(
        (state: any) => state.view.gdsMenu.state,
        isActiveMenu => {
            if (!isActiveMenu) {
                this.removeHighlighter()
            }
        },
        {
            immediate: true,
        }
    )
}
